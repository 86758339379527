import React from 'react'

const topArrowIcon = () => (
  <svg width='32px' height='40px' viewBox='0 0 32 40' version='1.1' xmlns='http://www.w3.org/2000/svg' >
    <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g transform='translate(-256.000000, -4769.000000)' fill='#FFFFFF' fillRule='nonzero'>
        <g id='cta' transform='translate(240.000000, 4769.000000)'>
          <path d='M32,29.984375 C34.84375,29.921875 37.203125,28.953125 39.078125,27.078125 C40.953125,25.203125 41.921875,22.84375 41.984375,20 C41.921875,17.15625 40.953125,14.796875 39.078125,12.921875 C37.203125,11.046875 34.84375,10.078125 32,10.015625 C29.15625,10.078125 26.796875,11.046875 24.921875,12.921875 C23.046875,14.796875 22.078125,17.15625 22.015625,20 C22.078125,22.84375 23.046875,25.203125 24.921875,27.078125 C26.796875,28.953125 29.15625,29.921875 32,29.984375 Z M34.015625,23.984375 L29.984375,23.984375 L29.984375,20 L26.984375,20 L32,14.984375 L37.015625,20 L34.015625,20 L34.015625,23.984375 Z' id='' />
        </g>
      </g>
    </g>
  </svg>
)

export default topArrowIcon

import React from 'react'

const twitterIcon = () => (
  <svg width='36px' height='40px' viewBox='0 0 36 40' version='1.1' xmlns='http://www.w3.org/2000/svg'>
    <g id='UI-360---02' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Francesco-Strazzullo---Close-menu-360x640' transform='translate(-50.000000, -100.000000)'>
        <g id='SOCIAL' transform='translate(-12.000000, 100.000000)'>
          <g id='ico' transform='translate(62.000000, 0.000000)'>
            <rect id='Rectangle' x='0' y='0' width='36' height='40' />
            <path d='M25.8672202,14.6358444 C25.7600511,14.5590742 25.6180671,14.5632326 25.5145308,14.6457605 C25.3786017,14.7545182 25.1576032,14.8408847 24.9196514,14.9074188 C25.5393553,14.173624 25.5526758,13.6835744 25.5460156,13.5709781 C25.5390526,13.4545434 25.4727531,13.3512235 25.3728496,13.3016428 C25.2726435,13.2517422 25.1551813,13.2638975 25.0658737,13.3323509 C24.3686689,13.8678228 23.6741888,13.9807389 23.2612547,13.9922544 C22.6273221,13.3512235 21.8041785,13 20.9280558,13 C19.0522934,13 17.5264958,14.6339251 17.5264958,16.6421048 C17.5264958,16.8148377 17.5379998,16.9872507 17.5607051,17.1580643 C14.3099085,17.1193593 11.6700364,13.8281582 11.64279,13.7939315 C11.5792151,13.7139626 11.4829445,13.6710992 11.384252,13.6803756 C11.2855596,13.6893321 11.1974629,13.7485092 11.1481167,13.8393539 C10.2126574,15.5538876 10.8387188,17.0598625 11.410893,17.9222474 C11.3097786,17.8653095 11.2277367,17.8090114 11.1786932,17.7709461 C11.0878719,17.699294 10.9664741,17.6874586 10.8641488,17.740558 C10.7621262,17.7936573 10.6970376,17.9024151 10.6949185,18.0223685 C10.6697912,19.6562936 11.4139203,20.5922499 12.1138497,21.1098088 C12.0206065,21.1005324 11.9258497,21.1369982 11.8610638,21.2115292 C11.7884068,21.2950168 11.7626741,21.4130509 11.7941588,21.5214888 C12.2776308,23.1934791 13.464665,23.7615784 14.2199954,23.9544634 C12.6669514,25.232047 10.3812823,24.8206868 10.3561551,24.8162086 C10.2181067,24.7902986 10.0818748,24.8670688 10.0255656,25.0023762 C9.96955911,25.1373638 10.007704,25.2957022 10.1191115,25.3852674 C11.7584358,26.7073137 13.7192674,27 15.135474,27 C16.2068625,27 16.9670366,26.8323851 17.0396937,26.8154317 C24.21397,25.0190098 24.415896,18.0754678 24.3992454,16.9376699 C25.7461251,15.6143441 25.9565278,15.11022 25.9889207,14.9854685 C26.0237355,14.8533598 25.974692,14.7126145 25.8672202,14.6358444 Z' id='Shape' fill='#FFFFFF' fillRule='nonzero' />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default twitterIcon
